import React from 'react';

import Layout from '../components/layout';

const IndexPage = () => (
  <Layout>
    <h1>Just a page with header</h1>
  </Layout>
);

export default IndexPage;
